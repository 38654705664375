<template>
  <v-container class="history">
    <v-row class="hide-events-row">
      <v-col cols="8"></v-col>
      <v-col cols="4">
        <v-btn
          hide-details
          class="
            ma-auto
            text-uppercase
            bold
            d-flex
            align-center
            text-center
            float-right
            bg-theme-primary
            add-comment
          "
          :class="{'white--text': isLifeChamps }"
          height="46"
          width="188"
          @click="commentDialog = true"
        >
          {{ $t("add_comment") }}
        </v-btn>
      </v-col>
      <AddCommentDialog
        :showDialog="commentDialog"
        @hideDialog="commentDialog = false"
      />
    </v-row>
    <v-row class="timeline-row">
      <v-skeleton-loader
        v-if="loading"
        type="list-item, list-item, list-item, list-item, list-item"
        class="w-100"
      ></v-skeleton-loader>

      <p
      v-else-if="!loading && (!history || history.length == 0)"
        class="w-100 text-center text-capitalize mt-10 title no-data-available"
      >
        {{ $t("no_data_available") }}!
      </p>

      <v-timeline dense v-else>
        <v-timeline-item fill-dot> </v-timeline-item>
        <v-timeline-item
          v-for="(event, n) in history"
          :key="n"
          large
          color="transparent"
        >
          <template v-slot:icon>
            <v-avatar width="34" height="34">
              <img
                v-if="event.type == 'comment'"
                :src="isLifeChamps ? require('@/assets/imgs/lifechamps-file.svg') : require('@/assets/imgs/file.svg')"
                class="responsive"
                height="22"
                width="20"
              />
              <img
                v-if="event.type == 'intervention'"
                :src="isLifeChamps ? require('@/assets/imgs/lifechamps-gear.svg') : require('@/assets/imgs/gear.svg')"
              />
            </v-avatar>
          </template>
          <div class="pt-3" v-if="event.type == 'comment'">
            <span class="date">{{ event.created_at }} </span>
            <span class="date">{{ $t("commented_by") }} </span>
            <span class="date">{{ event.admin_user }} </span>
            <span>"{{ event.comment }}"</span>
          </div>
          <div class="pt-3" v-if="event.type == 'intervention'">
            <span class="date">{{ event.created_at }} </span>
            <span class="date">{{ $t("edition_by") }} </span>
            <span class="date">{{ event.admin_user }} </span>
            <span>{{
              $t("history_intervention_text", {
                oldVal: event.old_values.intervened,
                newVal: event.new_values.intervened,
              })
            }}</span>
          </div>
        </v-timeline-item>
      </v-timeline>
    </v-row>
  </v-container>
</template>

<script>
import AddCommentDialog from "@/components/dialogs/AddCommentDialog.vue";

export default {
  name: "History",

  props: {
    history: {
      type: [Array, Object],
      default: () => null,
    },
  },

  data() {
    return {
      commentDialog: false,
      loading: false
    };
  },

  computed: {
    isLifeChamps: function () {
      return (
        this.$store.getters["auth/user"]?.theme_config.key == "lifechamps"
      );
    },
  },

  components: {
    AddCommentDialog,
  },

  methods: {
    comment() {
      this.$emit("comment");
    },
  },
};
</script>

<style
  lang="scss"
  src="@/assets/sass/components/views/dashboard/members/id/partial/tabs/history.scss"
></style>
